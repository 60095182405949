import { useEffect } from "react";

const GoogleTranslate = () => {
    const googleTranslateElementInit = () => {
        if (!window.google || !window.google.translate) return; // Ensure the script has loaded properly
        if (!document.getElementById("google_translate_element").innerHTML) {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    autoDisplay: false,

                },
                "google_translate_element"
            );
        }
    };

    useEffect(() => {
        // Check if the script is already added
        if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
            var addScript = document.createElement("script");
            addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            );
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;


        }

        // Clean up script and translate element on component unmount
        return () => {
            const existingScript = document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
            const translateElement = document.getElementById("google_translate_element");

            if (translateElement) {
                translateElement.innerHTML = '';
            }
            delete window.googleTranslateElementInit;
        };
    }, []);

    return (
        <>
            {/* <div id="google_translate_element"></div> */}
            <div id="google_translate_element"></div>
            {/* Add the CSS directly in the component */}
            <style jsx="true">{`
        #google_translate_element .skiptranslate div,
        .goog-te-gadget .goog-te-combo {
          display: flex !important;
          font-size: 14px !important;
          color: purple !important;
          font-family: "Poppins", sans-serif !important;
          font-weight:400;
          width: 70% !important;
          padding: 3px 5px !important;
          border-radius: 10px !important;
        }
        .VIpgJd-ZVi9od-l4eHX-hSRGPd {
        display: none !important;
        }
        .VIpgJd-ZVi9od-l4eHX-hSRGPd,  /* The Translate link and logo */
        .goog-te-banner-frame.skiptranslate, /* Any floating banner that might appear */
        .goog-logo-link, /* The "Powered by Google" link */
        .goog-te-gadget > span  { /* Additional spans that might include branding */
        display: none !important;
        }
        .goog-logo-link {
          display: none !important;
        }
        #google_translate_element {
          display: inline-block;
        }
        /* Hiding the Google Translate logo */
        .goog-logo-link {
          display: none !important;
        }
      .goog-te-gadget {
    display: flex !important;
    color: transparent !important;
    flex-direction: row; /* or 'column' */
    justify-content: center; /* Align items horizontally (center, start, end, space-between, etc.) */
    align-items: center; /* Align items vertically (center, start, end, stretch, etc.) */
}

        .skiptranslate > iframe {
          display: none !important;
        }
      `}</style>

        </>
    );
};

export default GoogleTranslate;
